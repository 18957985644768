<script>
import { geoLocationManager } from '../../../script/LiveLocationManager';

export default {
  name: "account_security",

  data() { return {
    securitySettings: {},
    loginLogs: {},
  }},

  created() {
    this.RequestData();
  },

  methods: {
    RequestData()
    {
      this.RequestSettings();
      this.RequestLoginLogs();
    },

    RequestSettings()
    {
      this.$api.get({ path: "user/settings/security" }, ({response}) => {
        const { security_settings } = response.data;
        this.securitySettings = security_settings;
      });
    },

    RequestLoginLogs()
    {

      this.$api.get({ path: "user/login-history" }, ({response}) => {
        const { login_history } = response.data;
        if (login_history) {
          return; 
        }

        this.loginLogs = login_history;
      });
    },

    ToggleSettings(fieldName)
    {
      this.$api.put({ path: `user/settings/security/${fieldName}` }, () => {
        this.securitySettings[fieldName] = !this.securitySettings[fieldName];
      })
    },

    RequestGeoLocationPerms()
    {
      geoLocationManager.RequestLocationPermission();
    },

    HasGeoLocationPermissions()
    {
      return geoLocationManager.permissionGranted;
    },
  },


}
</script>

<template>
<div class="security-body">
  <disabled-feature></disabled-feature>
  <div class="config-box">
    <check-box  v-for="(value, key) in securitySettings" :key="key" class="config-row" :checked="Boolean(value)" @check="ToggleSettings(key)" @unCheck="ToggleSettings(key)">
      {{$lan(`${key}_label`)}}
    </check-box>

    <check-box class="config-row" @check="RequestGeoLocationPerms" @unCheck="null" :checked="HasGeoLocationPermissions()">
      {{$lan('location_perms')}}
    </check-box>
  </div>

  <div v-if="loginLogs" class="login-logs bordered-box">
    <div class="config-row">
      Login: {{$utils.TimeStampToString(loginLogs.time)}}
    </div>
    <div class="config-row">
      IP: {{loginLogs.ip}}
    </div>
    <div class="config-row">
      Platform: {{loginLogs.platform}}
    </div>
    <div class="config-row">
      Country: {{loginLogs.country}}
    </div>
    <div class="config-row">
      Region: {{loginLogs.region}}
    </div>
    <div class="config-row">
      City: {{loginLogs.city}}
    </div>
  </div>

</div>
</template>

<style scoped>
.security-body {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-logs {
  padding: 10px;
  box-shadow: none;
  border: solid 1px var(--border-color);
}

.login-logs .config-row {
  padding: 10px;
}

.config-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
</style>